import { Bugs } from '$lib/BugsService';
import type { HandleClientError } from '@sveltejs/kit';

/**
 * Handle hooks
 */
// const i18nHandle: Handle = async ({ event, resolve }) => {
// 	event.locals.lang = 'da';
// 	return resolve(event);
// };

// export const handle = sequence(i18nHandle /*...otherHandleHooks */);

/**
 * Error hooks
 */
export const handleError: HandleClientError = (({ error, event, message }) => {
	const errorId = crypto.randomUUID();

	if (error instanceof Error) {
		Bugs.reportError(error, {
			error,
			event,
			errorId
		});
	} else {
		Bugs.reportError(new Error(message), {
			error,
			event,
			errorId
		});
	}

	console.error(error);
	return {
		message: 'An unexpected error occurred.',
		errorId
	};
}) satisfies HandleClientError;
